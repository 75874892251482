<template>
  <div id="app">
    <header v-if="!$route.name" class="header" />
    <main v-if="!$route.name" class="loader__main">
      <svg
        class="loader__spin"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
      >
        <use xlink:href="#symbol__loader-spin" />
      </svg>
    </main>
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    "$route.name"() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    /* eslint-disable */
    if(process.env.NODE_ENV === "production") {
      setTimeout(() => {
        (function(w,d,s,l,i) {
            w[l] = w[l] || [];
            w[l].push({"gtm.start": new Date().getTime(), event:"gtm.js"});
            var f = d.getElementsByTagName(s)[0],  
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j,f);
        }) (window,document,"script","dataLayer",window.envWebpack.METRICA_GTM_ID);
      }, 1000);
    }

    window.dataLayer = window.dataLayer || [];
    /* eslint-enable */
  },
};
</script>
