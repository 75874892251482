class Storage {
  constructor() {}

  get(name) {
    if (!localStorage) {
      throw new Error("localStorage is not exist");
    }

    let val = localStorage.getItem(name);
    if (!val) {
      return "";
    }

    val = JSON.parse(val);
    return val;
  }
  async set(name, val = "") {
    if (!localStorage) {
      throw new Error("localStorage is not exist");
    }

    val = JSON.stringify(val);
    localStorage.setItem(name, val);
  }
}

export default Storage;
