import {
  CREATE,
  VERIFICATION,
  PASSPORT,
  ADDITIONAL,
  CARD,
  WAITING_RESULT,
  PRIOR_APPROVAL,
  CHECK_CODE_AGREEMENT,
  CHOSE_PRODUCT,
  PRIOR_APPROVAL_IL,
  APPROVED,
  DECLINED,
  NEEDPHOTO,
  REPEATER,
  PROLONGATION_WAITING,
  PROLONGATION_PRIOR,
  PROLONGATION_CHECK_CODE,
} from "@/router/routes";

export default {
  _: CREATE,
  newbie_personal_info: CREATE,
  newbie_check_code_personal_info: VERIFICATION,
  newbie_passport: PASSPORT,
  newbie_additional_info: ADDITIONAL,
  newbie_transfer_type: CARD,
  newbie_waiting_result: WAITING_RESULT,
  newbie_prior_approval: PRIOR_APPROVAL,
  newbie_check_code_agreement: CHECK_CODE_AGREEMENT,
  newbie_chose_product: CHOSE_PRODUCT,
  newbie_prior_approval_il: PRIOR_APPROVAL_IL,
  newbie_approved: APPROVED,
  newbie_declined: DECLINED,

  repeater_transfer_type: REPEATER,
  repeater_waiting_result: WAITING_RESULT,
  repeater_chose_product: CHOSE_PRODUCT,
  repeater_prior_approval_il: PRIOR_APPROVAL_IL,
  repeater_prior_approval: PRIOR_APPROVAL,
  repeater_check_code_agreement: CHECK_CODE_AGREEMENT,
  repeater_approved: APPROVED,
  repeater_passport: PASSPORT,
  repeater_declined: DECLINED,

  need_photo: NEEDPHOTO,

  prolongation_waiting_result: PROLONGATION_WAITING,
  prolongation_prior_approval: PROLONGATION_PRIOR,
  prolongation_check_code_agreement: PROLONGATION_CHECK_CODE,

  getName(store, callback) {
    const { status = "", type = "" } =
      store.state.application.loan_application || {};
    const photo = store.state.application.client_documents;
    console.log(JSON.stringify({ status, type }));
    const { name } = !photo ? this[type + "_" + status] : this["need_photo"];
    callback(name);
  },
};
