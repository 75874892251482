import { createApp } from "vue";
import { createStore } from "@/store";
import { createRouter } from "@/router";

import App from "./app.vue";
import { createI18n } from "vue-i18n";
import { register } from "register-service-worker";
import * as Sentry from "@sentry/vue";

if ([...window.location.href.matchAll(/localhost|mk-dev/g)].length) {
  // eslint-disable-next-line promise/catch-or-return
  import(/* webpackChunkName: "snip" */ "my-snip").then(
    // eslint-disable-next-line promise/always-return
    ({ default: mySnipt }) => {
      mySnipt.loadByLongPress({
        file: import(/* webpackChunkName: "snip" */ "@/snippets-cy"),
        env: { TOKEN: "6508350740:AAFCAHZACm4WVgpWIPoN-lyQv9E1VtGY5j4" },
        onlyFromTags: ["BODY", "DIV"],
        timeout: 1000,
      });
    }
  );
}

export default function ({ infraLayer, UiKit, i18nMessages, swName }) {
  register(`${process.env.BASE_URL}${swName}`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });

  // eslint-disable-next-line sonarjs/prefer-object-literal
  const initObj = {};
  initObj.store = createStore(initObj);
  initObj.router = createRouter(initObj);

  const i18n = createI18n({
    locale: "ru",
    messages: i18nMessages,
  });

  const app = createApp(App);

  if (window.env.VUE_APP_SENTRY) {
    Sentry.init({
      app,
      dsn: "https://" + window.env.VUE_APP_SENTRY + "@sentry.mk-dev.ru/1",
      environment: window.env.VUE_APP_SENTRY_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(
            initObj.router
          ),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  app.use(initObj.router);
  app.use(initObj.store);
  app.use(infraLayer);
  app.use(UiKit);
  app.use(i18n);

  app.mount("#app");
}
