<template>
  <header :class="'form-header _' + theme">
    <div class="form-header__section">
      <Button v-if="isShowBack" class="ml-3" type="clear" @click="back">
        <Icon v-if="!isRequest" type="arrow-left" :to="toColorName" />
        <Icon v-if="isRequest" type="request" :to="toColorName" />
        <span v-if="theme === 'krediska'" class="mx-2 d-none d-md-block">
          Вернуться назад
        </span>
      </Button>
    </div>
    <div class="form-header__section form-header__section-title">
      <p class="form-header__title">
        <b v-if="title">{{ title }}</b>
        <span v-else>
          Вам одобрили до <b>{{ amount }}</b> ₽
        </span>
      </p>
    </div>
    <div class="form-header__section _counter">
      <div
        v-if="['beriberu', 'hurma'].includes(theme) && counterPages.showCounter"
        class="header-counter"
      >
        <span :class="'header-counter__item _' + theme">{{
          counterPages.firstPage
        }}</span
        >/{{ counterPages.allPages }}
      </div>
    </div>
  </header>
</template>

<script>
import morph from "@frontend/beryberu-main/services/morph";

export default {
  name: "FormHeader",
  props: {
    theme: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    term: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    isShowBack: {
      type: Boolean,
      default: false,
    },
    isRequest: {
      type: Boolean,
      default: false,
    },
    counterPages: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    morphDay() {
      return morph(["день", "дня", "дней"], this.term);
    },
    toColorName() {
      if (this.theme === "beriberu") {
        return "red";
      }

      if (this.theme === "hurma") {
        return "orange";
      }

      return "";
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="css" src="./form-header.css"></style>
