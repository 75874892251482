<template>
  <div :class="'progress-line _' + theme">
    <span class="progress-line__fill" :style="`width: ${percent}%`" />
  </div>
</template>

<script>
export default {
  name: "ProgressLine",
  props: {
    theme: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percent() {
      return Math.round((100 / this.total) * this.step);
    },
  },
};
</script>

<style lang="css" src="./progress-line.css"></style>
