import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      application: "application/applicationData",
      rates: "application/applicationRates",
      addincomeData: "application/applicationAddIncomeData",
    }),
  },
};
