import Storage from "@/ext/storage/storage";
const storage = new Storage();

export default {
  state: {
    data: null,
  },
  getters: {
    esiaData({ data }) {
      return data || {};
    },
  },
  mutations: {
    updateData(state, data) {
      state.data = data;
    },
  },
  actions: {
    async getData({ commit }) {
      try {
        const data = await storage.get("esia");
        commit("updateData", data);
      } catch (e) {
        console.warn(e);
      }
    },
    async setData({ commit }, data) {
      try {
        await storage.set("esia", data);
        commit("updateData", data);
      } catch (e) {
        console.warn(e);
      }
    },
    async completeStep({ state, dispatch }, step) {
      let { data } = state;
      data = data ? { ...data } : {};

      if (+step === 1) {
        delete data.name;
        delete data.surname;
        delete data.patronymic;
        delete data.phone;
        delete data.email;
      } else {
        data = {};
      }

      await dispatch("setData", data);
    },
  },
  namespaced: true,
};
