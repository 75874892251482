<template>
  <div :class="'form _' + theme">
    <div class="form__header">
      <FormHeader
        :theme="theme"
        :title="title"
        :term="application.term"
        :amount="application.amount"
        :is-show-back="showBack"
        :is-request="request"
        :counter-pages="counterPages"
        @back="onBack"
      />
      <ProgressLine :theme="theme" :total="6" :step="step" />
    </div>
    <div class="form__view-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import application from "@/mixins/application";
import FormHeader from "./components/form-header/form-header.vue";
import ProgressLine from "./components/progress-line/progress_line.vue";
import statusToRoute from "@/router/status-to-route";

export default {
  name: "PrimaryForm",
  components: {
    FormHeader,
    ProgressLine,
  },
  mixins: [application],
  data: () => ({
    request: false,
  }),
  computed: {
    title() {
      return (this.$route.meta || {}).title;
    },
    step() {
      const { status = "", type = "" } = this.application;

      return statusToRoute[type + "_" + status].step;
    },
    theme() {
      return window.envWebpack.theme;
    },
    showBack() {
      return this.$route.meta.back === undefined ? true : this.$route.meta.back;
    },
    counterPages() {
      const routes = {
        Create: 1,
        Verification: 1,
        Passport: 2,
        Additional: 3,
        WaitingResult: 3,
        Card: 4,
      };

      return {
        showCounter: routes[this.$route.name] ?? false,
        firstPage: routes[this.$route.name] ?? 1,
        allPages: routes[this.getLastPage(routes)],
      };
    },
  },
  watch: {
    "this.$store.state.application.init": {
      handler() {
        if (this.$store.state.application.init) {
          this.checkTermAndAmount();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("esia/getData");
  },
  methods: {
    onBack() {
      if (this.request) {
        return;
      }
      this.request = true;

      this.$infra.jsonrpc
        .back(this.application.id)
        .then(async ({ status }) => {
          if (status === "success") {
            await this.$store.dispatch("application/getStatusAndCheckRoute");
            this.request = false;
            return;
          }
          this.request = false;
          throw new Error();
        })
        .catch((err) => {
          console.error(err);
          throw err;
        });
    },
    getLastPage(routes) {
      return Object.keys(routes)[Object.keys(routes).length - 1];
    },
    checkTermAndAmount() {
      const { term, amount } = this.$route.query;

      if (!(term && amount)) {
        return;
      }

      this.$store.dispatch("application/updateTermAndAmount", {
        term,
        amount,
      });
    },
  },
};
</script>

<style lang="css" src="./form.css"></style>
