<template>
  <PrimaryForm v-if="theme !== 'dabank'">
    <router-view />
  </PrimaryForm>
  <PrimaryForm2 v-if="theme == 'dabank'">
    <router-view />
  </PrimaryForm2>
</template>

<script>
import PrimaryForm from "@/layouts/primary-form/primary_form.vue";
import PrimaryForm2 from "@/layouts/primary-form2/primary_form2.vue";

export default {
  name: "LayoutForm",
  components: {
    PrimaryForm,
    PrimaryForm2,
  },
  computed: {
    theme() {
      return window.envWebpack.theme;
    },
  },
};
</script>
