import { createStore as _createStore } from "vuex";

import esia from "./modules/esia";
import application from "./modules/application";
import routeMap from "@/router/status-to-route";

export function createStore(initObj) {
  return _createStore({
    mutations: {
      updateRoute(state) {
        routeMap.getName({ state }, (name) => {
          if (name) {
            initObj.router.push({
              name,
            });
          }
        });
      },
    },
    modules: {
      application,
      esia,
    },
    strict: true,
  });
}
